import React from "react";

// import Footer from "./Footer";

export default function FacebookPolicy() {
  return (
    <>
      <div className="container ">
        <div className="row d-flex align-items-center justify-content-center m-2 p-4">
          <p>&nbsp;</p>
          <p>Eventgem does not process sensitive personal information.</p>
          <p>&nbsp;</p>
          <p>
            We use the personal information collected only in the context of
            your interactions with Eventgem and its Services.
          </p>
          <p>&nbsp;</p>
          <p>
            The Facebook policy mandates us to provide a User Data Deletion
            Callback URL or Data Deletion Instructions URL.
          </p>
          <p>&nbsp;</p>
          <p>
            If you would like to delete your data and activity for the Eventgem
            platform, kindly follow the instructions below:
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>
              Go to your Facebook account&rsquo;s &ldquo;Settings &amp;
              Privacy&rdquo; section.
            </li>
            <li>Click &rdquo; Setting &ldquo;.</li>
            <li>
              Then, go to &rdquo; Apps and Websites,&rdquo; and you will see the
              list of applications integrated with your account.
            </li>
            <li>Select the option box for Eventgem.</li>
            <li>Click the&rdquo; Remove&rdquo; button.</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            For any further help, please contact our support at
            info@eventgem.com
          </p>
        </div>
      </div>
    </>
  );
}
