import React from "react";

export default function TermsAndConditions() {
  return (
    <>
      <div className="container ">
        <div className="row d-flex align-items-center justify-content-center m-2 p-4">
          <p style={{ textAlign: "center" }}>
            <strong>
              <span data-contrast="auto">TERMS AND CONDITIONS</span>
            </strong>
          </p>
          <p style={{ textAlign: "center" }}>
            <strong>
              <span data-contrast="auto">Last updated April 04, 2024</span>
            </strong>
          </p>
          <p style={{ textAlign: "center" }}>
            <strong>
              <span data-contrast="auto">AGREEMENT TO OUR LEGAL TERMS</span>
            </strong>
          </p>
          <p>
            <span data-contrast="auto">
              We are <strong>Eventgem</strong> ("Company," "we," "us," "our").
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We operate the website __________ (the "Site"), as well as any
              other related products and services that refer or link to these
              legal terms (the "Legal Terms") (collectively, the "Services").
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We provide tickets to events on our platform and also help
              organizers various shows for the{" "}
            </span>
            <span data-contrast="auto">Indian diaspora</span>
            <span data-contrast="auto"> in the US.</span>
          </p>
          <p>
            <span data-contrast="auto">
              You can contact us by email at __________ or by mail to
              __________, __________, __________.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity ("you"),
              and __________, concerning your access to and use of the Services.
              You agree that by accessing the Services, you have read,
              understood, and agreed to be bound by all of these Legal Terms. IF
              YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
              EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
              DISCONTINUE USE IMMEDIATELY.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              <span data-ccp-para="">
                We will provide you with prior notice of any scheduled changes
                to the Services you are using. Changes to Legal Terms will
                become effective __________ days after the notice is given. By
                continuing to use the Services after the effective date of any
                changes, you agree to be bound by the modified terms. If you
                disagree with such changes, you may{" "}
              </span>
              <span data-ccp-para="">terminate</span>
              <span data-ccp-para=""> Services as per the section</span>
            </span>
            <a href="bookmark://_3p4kztrolq9a">
              <span data-contrast="none">
                <span data-ccp-para=""> "TERM AND TERMINATION."</span>
              </span>
            </a>
          </p>
          <p>
            <span data-contrast="auto">
              The Services are intended for users who are at least 18 years old.
              Persons under the age of 18 are not permitted to use or register
              for the Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We recommend that you print a copy of these Legal Terms for your
              records.
            </span>
          </p>
          <p>
            <span data-contrast="auto">TABLE OF CONTENTS</span>
          </p>
          <ol>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> OUR SERVICES</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> INTELLECTUAL PROPERTY RIGHTS</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER REPRESENTATIONS</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER REGISTRATION</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> PURCHASES AND PAYMENT</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para="">CANCELLATION </span>
              </span>
              <span data-contrast="none">
                <span data-ccp-para="">POLICY</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para="">REFUND </span>
                <span data-ccp-para="">POLICY </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> PROHIBITED ACTIVITIES</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER GENERATED CONTRIBUTIONS </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CONTRIBUTION LICENSE </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> GUIDELINES FOR REVIEWS</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> SOCIAL MEDIA</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> SERVICES MANAGEMENT </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> PRIVACY POLICY </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> TERM AND TERMINATION </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> MODIFICATIONS AND INTERRUPTIONS</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> GOVERNING LAW</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> DISPUTE RESOLUTION</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CORRECTIONS</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> DISCLAIMER</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> LIMITATIONS OF LIABILITY</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> INDEMNIFICATION</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER DATA</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para="">
                  {" "}
                  ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES{" "}
                </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> SMS TEXT MESSAGING</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CALIFORNIA USERS AND RESIDENTS</span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> MISCELLANEOUS </span>
              </span>
            </li>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CONTACT US</span>
              </span>
            </li>
          </ol>
          <ol>
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> OUR SERVICES</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would be
              contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country.
              Accordingly, those persons who choose to access the Services from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws if and to the extent
              local laws are applicable.
            </span>
          </p>
          <p>
            <span data-contrast="auto">__________</span>
          </p>
          <ol start="2">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> INTELLECTUAL PROPERTY RIGHTS</span>
              </span>
            </li>
          </ol>
          <p>
            <strong>
              <span data-contrast="auto">Our Intellectual Property</span>
            </strong>
          </p>
          <p>
            <span data-contrast="auto">
              We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics in the Services (collectively, the
              "Content"), as well as the trademarks, service marks, and logos
              contained therein (the "Marks").
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              Our Content and Marks are protected by copyright and trademark
              laws (and various other intellectual property rights and unfair
              competition laws) and treaties in the United States and around the
              world.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              The Content and Marks are provided in or through the Services "AS
              IS" for your personal, non-commercial use or internal business
              purpose only.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Your use of our Services</span>
            </strong>
          </p>
          <p>
            <span data-contrast="auto">
              Subject to your compliance with these Legal Terms, including the{" "}
            </span>
            <a href="bookmark://_nsd9tkkmt0ou">
              <span data-contrast="none">"PROHIBITED ACTIVITIES&rdquo; </span>
            </a>
            <span data-contrast="auto">
              section below, we grant you a non-exclusive, non-transferable,
              revocable license to:
            </span>
          </p>
          <p>
            <span data-contrast="auto">* access the Services; and</span>
          </p>
          <p>
            <span data-contrast="auto">
              * download or print a copy of any portion of the Content to which
              you have properly gained access.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              solely for your personal, non-commercial use or internal business
              purpose.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              Except as set out in this section or elsewhere in our Legal Terms,
              no part of the Services and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              If you wish to make any use of the Services, Content, or Marks
              other than as set out in this section or elsewhere in our Legal
              Terms, please address your request to: __________. If we ever
              grant you permission to post, reproduce, or publicly display any
              part of our Services or Content, you must identify us as the
              owners or licensors of the Services, Content, or Marks and ensure
              that any copyright or proprietary notice appears or is visible on
              posting, reproducing, or displaying our Content.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We reserve all rights not expressly granted to you in and to the
              Services, Content, and Marks.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              Any breach of these Intellectual Property Rights will constitute a
              material breach of our Legal Terms and your right to use our
              Services will terminate immediately.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Your submissions</span>
            </strong>
          </p>
          <p>
            <span data-contrast="auto">
              Please review this section and the{" "}
            </span>
            <a href="bookmark://_nsd9tkkmt0ou">
              <span data-contrast="none">"PROHIBITED ACTIVITIES"</span>
            </a>
            <span data-contrast="auto">
              {" "}
              section carefully prior to using our Services to understand the
              (a) rights you give us and (b) obligations you have when you post
              or upload any content through the Services.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Submissions:</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              By directly sending us any question, comment, suggestion, idea,
              feedback, or other information about the Services ("Submissions"),
              you agree to assign to us all intellectual property rights in such
              Submission. You agree that we shall own this Submission and be
              entitled to its unrestricted use and dissemination for any lawful
              purpose, commercial or otherwise, without acknowledgment or
              compensation to you.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                You are responsible for what you post or upload:
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              By sending us Submissions through any part of the Services you:
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * confirm that you have read and agree with our "PROHIBITED
              ACTIVITIES " and will not post, send, publish, upload, or transmit
              through the Services any Submission that is illegal, harassing,
              hateful, harmful, defamatory, obscene, bullying, abusive,
              discriminatory, threatening to any person or group, sexually
              explicit, false, inaccurate, deceitful, or misleading;
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * to the extent permissible by applicable law, waive any and all
              moral rights to any such Submission;
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * warrant that any such Submission are original to you or that you
              have the necessary rights and licenses to submit such Submissions
              and that you have full authority to grant us the above-mentioned
              rights in relation to your Submissions; and
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * warrant and represent that your Submissions do not constitute
              confidential information.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              You are solely responsible for your Submissions and you expressly
              agree to reimburse us for any and all losses that we may suffer
              because of your breach of (a) this section, (b) any third
              party&rsquo;s intellectual property rights, or (c) applicable law.
            </span>
          </p>
          <ol start="3">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER REPRESENTATIONS</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              By using the Services, you represent and warrant that: (1) all
              registration information you submit will be true, accurate,
              current, and complete; (2) you will maintain the accuracy of such
              information and promptly update such registration information as
              necessary; (3) you have the legal capacity and you agree to comply
              with these Legal Terms; (4) you are not a minor in the
              jurisdiction in which you reside; (5) you will not access the
              Services through automated or non-human means, whether through a
              bot, script or otherwise; (6) you will not use the Services for
              any illegal or unauthorized purpose; and (7) your use of the
              Services will not violate any applicable law or regulation.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Services (or any portion thereof).
            </span>
          </p>
          <ol start="4">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER REGISTRATION</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              You may be required to register to use the Services. You agree to
              keep your password confidential and will be responsible for all
              use of your account and password. We reserve the right to remove,
              reclaim, or change a username you select if we determine, in our
              sole discretion, that such username is inappropriate, obscene, or
              otherwise objectionable.
            </span>
          </p>
          <ol start="5">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> PURCHASES AND PAYMENT</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              We accept the following forms of payment:
            </span>
          </p>
          <p>
            <span data-contrast="auto">- PayPal</span>
          </p>
          <p>
            <span data-contrast="auto">- Visa</span>
          </p>
          <p>
            <span data-contrast="auto">- Mastercard</span>
          </p>
          <p>
            <span data-contrast="auto">- American Express</span>
          </p>
          <p>
            <span data-contrast="auto">- Gpay</span>
          </p>
          <p>
            <span data-contrast="auto">- Apple pay</span>
          </p>
          <p>
            <span data-contrast="auto">
              You agree to provide current, complete, and accurate purchase and
              account information for all purchases made via the Services. You
              further agree to promptly update account and payment information,
              including email address, payment method, and payment card
              expiration date, so that we can complete your transactions and
              contact you as needed. Sales tax will be added to the price of
              purchases as deemed required by us. We may change prices at any
              time. All payments shall be in US dollars.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you authorize us
              to charge your chosen payment provider for any such amounts upon
              placing your order. We reserve the right to correct any errors or
              mistakes in pricing, even if we have already requested or received
              payment.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We reserve the right to refuse any order placed through the
              Services. We may, in our sole discretion, limit or cancel
              quantities purchased per person, per household, or per order.
              These restrictions may include orders placed by or under the same
              customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole judgment, appear to be
              placed by dealers, resellers, or distributors.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">Payment Data. </span>
            </strong>
            <span data-contrast="none">
              We may collect data necessary to process your payment if you make
              purchases, such as your payment instrument number, and the
              security code associated with your payment instrument. All payment
              data is stored by Stripe. You may find their privacy notice
              link(s) here:{" "}
            </span>
            <a href="https://stripe.com/in/privacy">
              <span data-contrast="none">
                <span data-ccp-char="">https://stripe.com/in/privacy</span>
              </span>
            </a>
            <span data-contrast="none">.</span>
          </p>
          <ol start="6">
            <li>
              <span data-contrast="none">
                <span data-ccp-para="">CANCELLATION POLICY</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="none">
              Any ticket purchased under the Eventgem platform is meant solely
              and exclusively for the person who bought it and in whose name the
              ticket(s) have been issued. Once purchased, you cannot exchange or
              adjust/transfer for any other shows.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              All sales are final, and the ticket(s) on any bookings cannot be
              canceled or exchanged unless specified otherwise in the event
              organizer's terms and conditions.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Events can be occasionally canceled, rescheduled, or postponed to
              a different date, time, or venue. The event organizer will duly
              inform you regarding the updated information and the status of the
              cancellation and refund formalities and procedures.
            </span>
          </p>
          <ol start="7">
            <li>
              <span data-contrast="none">
                <span data-ccp-para="">REFUND </span>
                <span data-ccp-para="">POLICY</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="none">
              As mentioned above in the cancellation policy, all sales are
              final. However, our refund policy is flexible under special
              circumstances such as a canceled, rescheduled, postponed, or moved
              event. This is to ensure that you feel reassured and accommodated
              in unforeseen situations.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              It is important to thoroughly review the event details and the
              seats selected before proceeding to payment to ensure that you are
              fully informed and responsible for your purchase.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Please carefully review the cancellation and refund policies in
              the terms of use before booking an event.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">In the event of a cancellation</span>
            </strong>
            <span data-contrast="none">
              , Eventgem will promptly issue a complete refund to the original
              mode of payment once the funds are released from the organizer.
              This process is designed to ensure your security and trust in our
              platform.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                If an event is rescheduled, postponed, or moved to a different
                venue
              </span>
            </strong>
            <span data-contrast="none">
              , and you are not willing to attend on the new date, time, or
              venue, you can request a refund. The event organizer will approve
              the refund.&nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If you are attending the event, the original tickets, upgrades,
              and add-ons are valid, and no further actions are necessary.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If you still have any questions, please refer to the terms of use
              and organizer terms and conditions for the specific event or
              contact us at Eventgem support.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              The event organizer(or us) will refund the original ticket fee,
              service fee, and payment processing fee: more clarity is
              necessary(are service fees refundable or not)
            </span>
          </p>
          <p>
            <span data-contrast="none">
              In the event of a failed payment where you have not received a
              booking confirmation via email or your registered mobile number,
              Eventgem will promptly refund the amount once the payment failure
              is confirmed. Please note that the refund process may take 5 to 7
              days depending on the time the financial institution takes to
              credit the amount back to your account.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">Important notes</span>
            </strong>
          </p>
          <p>
            <span data-contrast="none">
              Eventgem reserves the right to refuse refunds or ticket exchanges
              if they violate the event organizer's terms or our policies.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Attempts to circumvent refund policies through chargebacks or
              other means may result in ticket cancellation and restrictions on
              future purchases.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Service fees may or may not be refundable, depending on the
              circumstances. Do not hesitate to get in touch with the event
              organizer or Eventgem Support for specific details regarding your
              situation.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              This Cancellation and Refund Policy is governed by the laws
              applicable to Eventgem's primary place of business.
            </span>
          </p>
          <ol start="8">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> PROHIBITED ACTIVITIES</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              You may not access or use the Services for any purpose other than
              that for which we make the Services available. The Services may
              not be used in connection with any commercial endeavors except
              those that we specifically endorse or approve.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              As a user of the Services, you agree not to:
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Systematically retrieve data or other content from the Services
              to create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Circumvent, disable, or otherwise interfere with
              security-related features of the Services, including features that
              prevent or restrict the use or copying of any Content or enforce
              limitations on the use of the Services and/or the Content
              contained therein.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Use any information obtained from the Services in order to
              harass, abuse, or harm another person.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Make improper use of our support services or submit false
              reports of abuse or misconduct.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Use the Services in a manner inconsistent with any applicable
              laws or regulations.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Engage in unauthorized framing of or linking to the Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Upload or transmit (or attempt to upload or to transmit)
              viruses, Trojan horses, or other material, including excessive use
              of capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party&rsquo;s uninterrupted use
              and enjoyment of the Services or modifies, impairs, disrupts,
              alters, or interferes with the use, features, functions,
              operation, or maintenance of the Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Delete the copyright or other proprietary rights notice from any
              Content.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Attempt to impersonate another user or person or use the
              username of another user.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats ("gifs"), 1&times;1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to as
              "spyware" or "passive collection mechanisms" or "pcms").
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Interfere with, disrupt, or create an undue burden on the
              Services or the networks or services connected to the Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Services to you.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Attempt to bypass any measures of the Services designed to
              prevent or restrict access to the Services, or any portion of the
              Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Copy or adapt the Services' software, including but not limited
              to Flash, PHP, HTML, JavaScript, or other code.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Except as may be the result of standard search engine or
              Internet browser usage, use, launch, develop, or distribute any
              automated system, including without limitation, any spider, robot,
              cheat utility, scraper, or offline reader that accesses the
              Services, or use or launch any unauthorized script or other
              software.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Use a buying agent or purchasing agent to make purchases on the
              Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Make any unauthorized use of the Services, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or creating
              user accounts by automated means or under false pretenses.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavor or commercial enterprise.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Sell or otherwise transfer your profile.
            </span>
          </p>
          <ol start="9">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER GENERATED CONTRIBUTIONS</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              The Services does not offer users to submit or post content. We
              may provide you with the opportunity to create, submit, post,
              display, transmit, perform, publish, distribute, or broadcast
              content and materials to us or on the Services, including but not
              limited to text, writings, video, audio, photographs, graphics,
              comments, suggestions, or personal information or other material
              (collectively, "Contributions"). Contributions may be viewable by
              other users of the Services and through third-party websites. As
              such, any Contributions you transmit may be treated in accordance
              with the Services' Privacy Policy. When you create or make
              available any Contributions, you thereby represent and warrant
              that:
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * You are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to
              authorize us, the Services, and other users of the Services to use
              your Contributions in any manner contemplated by the Services and
              these Legal Terms.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these
              Legal Terms.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions are not false, inaccurate, or misleading.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions are not unsolicited or unauthorized
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous, or otherwise
              objectionable (as determined by us).
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions do not ridicule, mock, disparage, intimidate,
              or abuse anyone.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions do not violate any applicable law,
              regulation, or rule.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions do not violate the privacy or publicity
              rights of any third party.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of minors.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions do not include any offensive comments that
              are connected to race, national origin, gender, sexual preference,
              or physical handicap.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              * Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Legal Terms, or any
              applicable law or regulation.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              Any use of the Services in violation of the foregoing violates
              these Legal Terms and may result in, among other things,
              termination or suspension of your rights to use the Services.
            </span>
          </p>
          <ol start="10">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CONTRIBUTION LICENSE</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              You and Services agree that we may access, store, process, and use
              any information and personal data that you provide following the
              terms of the Privacy Policy and your choices (including settings).
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              By submitting suggestions or other feedback regarding the
              Services, you agree that we can use and share such feedback for
              any purpose without compensation to you.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              on the Services. You are solely responsible for your Contributions
              to the Services and you expressly agree to exonerate us from any
              and all responsibility and to refrain from any legal action
              against us regarding your Contributions.
            </span>
          </p>
          <ol start="11">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> GUIDELINES FOR REVIEWS</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              We may provide you areas on the Services to leave reviews or
              ratings. When posting a review, you must comply with the following
              criteria: (1) you should have firsthand experience with the
              person/entity being reviewed; (2) your reviews should not contain
              offensive profanity, or abusive, racist, offensive, or hateful
              language; (3) your reviews should not contain discriminatory
              references based on religion, race, gender, national origin, age,
              marital status, sexual orientation, or disability; (4) your
              reviews should not contain references to illegal activity; (5) you
              should not be affiliated with competitors if posting negative
              reviews; (6) you should not make any conclusions as to the
              legality of conduct; (7) you may not post any false or misleading
              statements; and (8) you may not organize a campaign encouraging
              others to post reviews, whether positive or negative.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We may accept, reject, or remove reviews at our sole discretion.
              We have absolutely no obligation to screen reviews or to delete
              reviews, even if anyone considers reviews objectionable or
              inaccurate. Reviews are not endorsed by us and do not necessarily
              represent our opinions or the views of any of our affiliates or
              partners. We do not assume liability for any review or for any
              claims, liabilities, or losses resulting from any review. By
              posting a review, you hereby grant to us a perpetual,
              non-exclusive, worldwide, royalty-free, fully paid, assignable,
              and sublicensable right and license to reproduce, modify,
              translate, transmit by any means, display, perform, and/or
              distribute all content relating to the review.
            </span>
          </p>
          <ol start="12">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> SOCIAL MEDIA</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              As part of the functionality of the Services, you may link your
              account with online accounts you have with third-party service
              providers (each such account, a "Third-Party Account") by either:
              (1) providing your Third-Party Account login information through
              the Services; or (2) allowing us to access your Third-Party
              Account, as is permitted under the applicable terms and conditions
              that govern your use of each Third-Party Account. You represent
              and warrant that you are entitled to disclose your Third-Party
              Account login information to us and/or grant us access to your
              Third-Party Account, without breach by you of any of the terms and
              conditions that govern your use of the applicable Third-Party
              Account, and without obligating us to pay any fees or making us
              subject to any usage limitations imposed by the third-party
              service provider of the Third-Party Account. By granting us access
              to any Third-Party Accounts, you understand that (1) we may
              access, make available, and store (if applicable) any content that
              you have provided to and stored in your Third-Party Account (the
              "Social Network Content") so that it is available on and through
              the Services via your account, including without limitation any
              friend lists and (2) we may submit to and receive from your
              Third-Party Account additional information to the extent you are
              notified when you link your account with the Third-Party Account.
              Depending on the Third-Party Accounts you choose and subject to
              the privacy settings that you have set in such Third-Party
              Accounts, personally identifiable information that you post to
              your Third-Party Accounts may be available on and through your
              account on the Services. Please note that if a Third-Party Account
              or associated service becomes unavailable or our access to such
              Third-Party Account is terminated by the third-party service
              provider, then Social Network Content may no longer be available
              on and through the Services. You will have the ability to disable
              the connection between your account on the Services and your
              Third-Party Accounts at any time. PLEASE NOTE THAT YOUR
              RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
              WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
              AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no
              effort to review any Social Network Content for any purpose,
              including but not limited to, for accuracy, legality, or
              non-infringement, and we are not responsible for any Social
              Network Content. You acknowledge and agree that we may access your
              email address book associated with a Third-Party Account and your
              contacts list stored on your mobile device or tablet computer
              solely for purposes of identifying and informing you of those
              contacts who have also registered to use the Services. You can
              deactivate the connection between the Services and your
              Third-Party Account by contacting us using the contact information
              below or through your account settings (if applicable). We will
              attempt to delete any information stored on our servers that was
              obtained through such Third-Party Account, except the username and
              profile picture that become associated with your account.
            </span>
          </p>
          <ol start="13">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> SERVICES MANAGEMENT</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              We reserve the right, but not the obligation, to: (1) monitor the
              Services for violations of these Legal Terms; (2) take appropriate
              legal action against anyone who, in our sole discretion, violates
              the law or these Legal Terms, including without limitation,
              reporting such user to law enforcement authorities; (3) in our
              sole discretion and without limitation, refuse, restrict access
              to, limit the availability of, or disable (to the extent
              technologically feasible) any of your Contributions or any portion
              thereof; (4) in our sole discretion and without limitation,
              notice, or liability, to remove from the Services or otherwise
              disable all files and content that are excessive in size or are in
              any way burdensome to our systems; and (5) otherwise manage the
              Services in a manner designed to protect our rights and property
              and to facilitate the proper functioning of the Services.
            </span>
          </p>
          <ol start="14">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> PRIVACY POLICY</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              We care about data privacy and security. Please review our Privacy
              Policy: __________. By using the Services, you agree to be bound
              by our Privacy Policy, which is incorporated into these Legal
              Terms. Please be advised the Services are hosted in the United
              States. If you access the Services from any other region of the
              world with laws or other requirements governing personal data
              collection, use, or disclosure that differ from applicable laws in
              the United States, then through your continued use of the
              Services, you are transferring your data to the United States, and
              you expressly consent to have your data transferred to and
              processed in the United States.
            </span>
          </p>
          <ol start="15">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> TERM AND TERMINATION</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              These Legal Terms shall remain in full force and effect while you
              use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
              LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
              SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
              FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
              BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
              THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR
              ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
              TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </span>
          </p>
          <ol start="16">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> MODIFICATIONS AND INTERRUPTIONS</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              We reserve the right to change, modify, or remove the contents of
              the Services at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Services. We also reserve the right to modify
              or discontinue all or part of the Services without notice at any
              time. We will not be liable to you or any third party for any
              modification, price change, suspension, or discontinuance of the
              Services.
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              We cannot guarantee the Services will be available at all times.
              We may experience hardware, software, or other problems or need to
              perform maintenance related to the Services, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the
              Services at any time or for any reason without notice to you. You
              agree that we have no liability whatsoever for any loss, damage,
              or inconvenience caused by your inability to access or use the
              Services during any downtime or discontinuance of the Services.
              Nothing in these Legal Terms will be construed to obligate us to
              maintain and support the Services or to supply any corrections,
              updates, or releases in connection therewith.
            </span>
          </p>
          <ol start="17">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> GOVERNING LAW</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              These Legal Terms and your use of the Services are governed by and
              construed in accordance with the laws of the State of California
              applicable to agreements made and to be entirely performed within
              the State of California, without regard to its conflict of law
              principles.
            </span>
          </p>
          <ol start="18">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> DISPUTE RESOLUTION</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              Any legal action of whatever nature brought by either you or us
              (collectively, the "Parties" and individually, a "Party") shall be
              commenced or prosecuted in the state and federal courts located in
              __________, and the Parties hereby consent to, and waive all
              defenses of lack of personal jurisdiction and forum non conveniens
              with respect to venue and jurisdiction in such state and federal
              courts. Application of the United Nations Convention on Contracts
              for the International Sale of Goods and the Uniform Computer
              Information Transaction Act (UCITA) are excluded from these Legal
              Terms.
            </span>
          </p>
          <ol start="19">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CORRECTIONS</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              There may be information on the Services that contains
              typographical errors, inaccuracies, or omissions, including
              descriptions, pricing, availability, and various other
              information. We reserve the right to correct any errors,
              inaccuracies, or omissions and to change or update the information
              on the Services at any time, without prior notice.
            </span>
          </p>
          <ol start="20">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> DISCLAIMER</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
              AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
              THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
              THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
              THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE
              CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
              SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
              (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
              RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
              UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
              ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
              THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
              FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
              LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
              THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
              MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
              RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
              MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE,
              GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
              ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
              HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED
              IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO
              OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
              BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
              AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR
              IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
              CAUTION WHERE APPROPRIATE.
            </span>
          </p>
          <ol start="21">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> LIMITATIONS OF LIABILITY</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
              LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
              FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
              PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND
              INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
              OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
              APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
              MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </span>
          </p>
          <ol start="22">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> INDEMNIFICATION</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys&rsquo; fees and expenses, made by any third party due to
              or arising out of: (1) use of the Services; (2) breach of these
              Legal Terms; (3) any breach of your representations and warranties
              set forth in these Legal Terms; (4) your violation of the rights
              of a third party, including but not limited to intellectual
              property rights; or (5) any overt harmful act toward any other
              user of the Services with whom you connected via the Services.
              Notwithstanding the foregoing, we reserve the right, at your
              expense, to assume the exclusive defense and control of any matter
              for which you are required to indemnify us, and you agree to
              cooperate, at your expense, with our defense of such claims. We
              will use reasonable efforts to notify you of any such claim,
              action, or proceeding which is subject to this indemnification
              upon becoming aware of it.
            </span>
          </p>
          <ol start="23">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> USER DATA</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              We will maintain certain data that you transmit to the Services
              for the purpose of managing the performance of the Services, as
              well as data relating to your use of the Services. Although we
              perform regular routine backups of data, you are solely
              responsible for all data that you transmit or that relates to any
              activity you have undertaken using the Services. You agree that we
              shall have no liability to you for any loss or corruption of any
              such data, and you hereby waive any right of action against us
              arising from any such loss or corruption of such data.
            </span>
          </p>
          <p>
            <span data-contrast="none">
              <span data-ccp-para="">
                24.ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </span>
            </span>
          </p>
          <p>
            <span data-contrast="auto">
              Visiting the Services, sending us emails, and completing online
              forms constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Services, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SERVICES. You hereby waive any rights or requirements under
              any statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </span>
          </p>
          <ol start="25">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> SMS TEXT MESSAGING</span>
              </span>
            </li>
          </ol>
          <p>
            <strong>
              <span data-contrast="auto">Opting Out</span>
            </strong>
          </p>
          <p>
            <span data-contrast="auto">
              If at any time you wish to stop receiving SMS messages from us,
              simply reply to the text with "STOP.&rdquo; You may receive an SMS
              message confirming your opt out.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Message and Data Rates</span>
            </strong>
          </p>
          <p>
            <span data-contrast="auto">
              Please be aware that message and data rates may apply to any SMS
              messages sent or received. The rates are determined by your
              carrier and the specifics of your mobile plan.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Support</span>
            </strong>
          </p>
          <p>
            <span data-contrast="auto">
              If you have any questions or need assistance regarding our SMS
              communications, please email us at __________.
            </span>
          </p>
          <ol start="26">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CALIFORNIA USERS AND RESIDENTS</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </span>
          </p>
          <ol start="27">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> MISCELLANEOUS</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              These Legal Terms and any policies or operating rules posted by us
              on the Services or in respect to the Services constitute the
              entire agreement and understanding between you and us. Our failure
              to exercise or enforce any right or provision of these Legal Terms
              shall not operate as a waiver of such right or provision. These
              Legal Terms operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Legal Terms is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Legal Terms and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Legal Terms or use of the
              Services. You agree that these Legal Terms will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all defenses you may have based on the electronic form of
              these Legal Terms and the lack of signing by the parties hereto to
              execute these Legal Terms.
            </span>
          </p>
          <ol start="28">
            <li>
              <span data-contrast="none">
                <span data-ccp-para=""> CONTACT US</span>
              </span>
            </li>
          </ol>
          <p>
            <span data-contrast="auto">
              In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us at:
            </span>
          </p>
          <p>
            <span data-contrast="auto">__________</span>
          </p>
          <p>
            <span data-contrast="auto">__________</span>
          </p>
          <p>
            <span data-contrast="auto">__________</span>
          </p>
        </div>
      </div>
    </>
  );
}
