import React, { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import img from "../../Assets/login.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";

import "./css/ForgotPassword.css";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import forgotmodal from "../../Assets/forgotmodal.png";
import axios from "axios";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
export default function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [custId, setCustId] = useState();
  const [user, setUser] = useState();
  const Ref = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [typing, setTyping] = useState(false);
  const [strength, setStrength] = useState();
  const [passScore, setPassScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const custId = urlParams.get("cust");
    setCustId(custId);
    const getUserData = async () => {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const body = {
        cust: custId,
      };
      await axios
        .post(`${devApi}/api/resetPasswordCustData`, body, config)
        .then((response) => {
          if (response.data.success) {
            setUser(response.data.user);
          } else setUser("Not Found");
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    };
    getUserData();
  }, []);
  const handleInputChange = (event) => {
    let inputError = {
      email: "",
      password: "",
      confirm_password: "",
    };
    setErrors(inputError);
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    // Check password length
    if (password.length > 8) score += 1;
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    setPassScore(score);
    switch (score) {
      case 0:
      case 1:
      case 2:
        return (
          <span ref={Ref} className="weak">
            Weak
          </span>
        );
      case 3:
        return (
          <span ref={Ref} className="medium">
            Medium
          </span>
        );
      case 4:
        return (
          <span ref={Ref} className="strong">
            Strong
          </span>
        );
      case 5:
        return (
          <span ref={Ref} className="very-strong">
            Very Strong
          </span>
        );
      default:
        return null;
    }
  };
  const validateFormInput = async (event) => {
    event.preventDefault();
    let inputError = {
      email: "",
      password: "",
      confirm_password: "",
    };
    if (!formFields.password && !formFields.confirm_password) {
      setErrors({
        ...inputError,
        password: "New password is required",
        confirm_password: "Confirm password should match new password",
      });
      return;
    }
    if (formFields.password.length < 8) {
      setErrors({
        ...inputError,
        password: "Password should contain minimun 8 characters",
      });
      return;
    }
    if (formFields.password !== formFields.confirm_password) {
      setErrors({
        ...inputError,
        confirm_password: "Confirm password should match new password",
      });
      return;
    }
    if (!errors.password && !errors.confirm_password) {
      const body = {
        cust: custId,
        password: formFields.password,
        confirm_password: formFields.confirm_password,
      };
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      await axios
        .post(`${devApi}/api/confirmNewPassword`, body, config)
        .then((response) => {
          if (response.data.success) {
            setShowModal(true);
          } else {
            setErrors({
              ...errors,
              confirmPassword:
                "There was some error while settting your password",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    }
  };
  //   useEffect(() => {
  //     if (otpsent === true && seconds > 0) {
  //       const timerInterval = setInterval(() => {
  //         setSeconds((prevSeconds) => {
  //           if (prevSeconds === 1) {
  //             clearInterval(timerInterval);
  //           }
  //           return prevSeconds - 1;
  //         });
  //       }, 1000);

  //       return () => clearInterval(timerInterval);
  //     }
  //   }, [otpsent, seconds]);
  if (!user) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <div className="login-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 head-box">
            <h3>
              Catch the coolest events in your town right here, right now!
            </h3>
            <img src={img} alt="" className="img-fluid" />
          </div>

          {user !== "Not Found" ? (
            <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page">
              <Link to="/">
                <img src={eventgemLogo} alt="" />
              </Link>
              <h6>Welcome to hassle-free event bookings!</h6>
              <p style={{ fontWeight: "600", marginTop: "65px" }}>
                Set new password
              </p>
              <div className="d-flex justify-content-evenly align-items-center w-100">
                <div className="left-line w-100 mx-4"></div>
              </div>

              <div className="form-box w-100">
                <div className="mb-2">
                  <label htmlFor="" className="forgot-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Email"
                    name="email"
                    style={{ backgroundColor: "#F3F3F3" }}
                    value={user ? user.email : ""}
                    disabled
                  />
                </div>
                <div className="mb-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <label for="password" className="signup-label">
                      Password
                    </label>
                    <div className="ms-2">{strength}</div>
                  </div>
                  <div className="d-flex align-items-center signup-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="signup-input-password"
                      placeholder="Enter Password"
                      name="password"
                      onChange={(event) => {
                        handleInputChange(event);
                        setStrength(
                          evaluatePasswordStrength(event.target.value)
                        );
                      }}
                      onFocus={() => setTyping(true)}
                      onBlur={() => setTyping(false)}
                      value={formFields.password}
                      id="password"
                      aria-describedby="showHide"
                    />
                    <span
                      className="show-hide"
                      id="showHide"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye show-hide"></i>
                      ) : (
                        <i
                          className="bi bi-eye-slash show-hide"
                          id="togglePassword"
                        ></i>
                      )}
                    </span>
                  </div>
                  {typing && passScore !== 5 && (
                    <small className="text-primary ml-2 me-2">
                      Use 8 or more characters with a mix of letters, numbers &
                      symbols
                    </small>
                  )}
                  <small className="text-danger ml-2">{errors.password}</small>
                </div>

                <div className="mb-2">
                  <label for="confirm_password" className="signup-label">
                    Re-Enter New Password
                  </label>
                  <div className="d-flex align-items-center signup-input">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="signup-input-password"
                      placeholder="Re-Enter Password"
                      name="confirm_password"
                      onChange={handleInputChange}
                      value={formFields.confirm_password}
                    />
                    <span
                      className="show-hide"
                      id="showHide"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <i className="bi bi-eye show-hide"></i>
                      ) : (
                        <i className="bi bi-eye-slash show-hide"></i>
                      )}
                    </span>
                  </div>
                  <small className="text-danger ml-2">
                    {errors.confirm_password}
                  </small>
                </div>
              </div>
              {loading === true ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="login-btn" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              ) : (
                <button
                  type="submit"
                  className={
                    formFields.password === "" ||
                    formFields.confirmPassword === ""
                      ? "login-btn-disable mt-2"
                      : "login-btn mt-2"
                  }
                  onClick={validateFormInput}
                >
                  Reset Password
                </button>
              )}
            </div>
          ) : (
            <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="d-flex justify-content-center text-center align-items-center ">
                  <h1 className="text-center">User Not Found</h1>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="forgot-modal">
            <h2>Password Reset Success</h2>
            <p>Your Password has been successfully reset.</p>
            <div className="d-flex justify-content-center">
              <img src={forgotmodal} alt="" className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center">
              <Link className="login-btn my-2" to="/login">
                Login
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
