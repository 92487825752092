import React, { useEffect } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
export default function Profile() {
  const user = useSelector((state) => state.user.user);
  const history = useNavigate();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <div className="container  ">
      {/*<div className="d-flex align-items-center">
        <button className="btn" onClick={() => history(-1)}>
          <div className="d-inline-flex">
            <MdKeyboardArrowLeft />
            <h2 className="back-btn ms-2">Go Back</h2>
          </div>
        </button>
  </div>*/}
      <div
        className="row d-flex align-items-center justify-content-center"
        style={{ height: "50vh" }}
      >
        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">Profile</div>
            <div className="card-body">
              <blockquote className="blockquote mb-0">
                <p className="mb-1">
                  {" "}
                  Name : {user.first_name ? user.first_name : "-"}
                </p>
                <p className="mb-1"> Email : {user.email ? user.email : "-"}</p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
