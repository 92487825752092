import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom/dist";
import img from "../../Assets/login.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import OtpInput from "react-otp-input";
import "./css/ForgotPassword.css";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import forgotmodal from "../../Assets/forgotmodal.png";
import { useSelector } from "react-redux";
import { devApi } from "../../utils/constants";
// import { verifyEmail } from "../../APICalls/forgotPasswordCalls";
import axios from "axios";
import validator from "validator";
import { toast } from "react-toastify";
export default function ForgotPassword() {
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);
  const [emailField, setEmailField] = useState(true);
  const [otpsent, setOtpsent] = useState(false);
  const [newPasswordField, setNewPasswordField] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    email: "",
  });
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [messages, setMessages] = useState({
    emailError: "",
    emailSuccess: "",
    emailCheck:
      "Do check your spam and junk email folder if you don't find email in your primary inbox",
  });
  const updateData = (e) => {
    if (messages.emailError) {
      setMessages({ ...messages, emailError: null });
    }
    setData({
      ...data,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
  };
  const resetPassword = async () => {
    setLoading(true);
    if (!validator.isEmail(data.email)) {
      setMessages({ ...messages, emailError: "Please enter a valid email" });
      setLoading(false);
      return;
    }
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    };
    const body = {
      email: data.email,
    };

    await axios
      .post(`${devApi}/api/resetPasswordLink`, body, config)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setDisable(true);
          setMessages({ ...messages, emailSuccess: response.data.message });
        } else {
          setLoading(false);

          setMessages({ ...messages, emailError: response.data.message });
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessages({ ...messages, emailError: error.response.data.message });
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });

    // setOtpsent(true);
    // setNewPasswordField(true);
    // setEmailField(false);
  };
  const reset = () => {
    setShowModal(true);
  };
  useEffect(() => {
    if (otpsent === true && seconds > 0) {
      const timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval);
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [otpsent, seconds]);
  const remainingSeconds = seconds % 60;
  const verifyOtp = () => {
    setNewPasswordField(true);
    setOtpsent(false);
  };
  const location = useLocation();
  return (
    <div className="login-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 head-box">
            <h3>
              Catch the coolest events in your town right here, right now!
            </h3>
            <img src={img} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page">
            <Link to="/">
              <img src={eventgemLogo} alt="" />
            </Link>
            <h6>Welcome to hassle-free event bookings!</h6>
            <p style={{ fontWeight: "600", marginTop: "65px" }}>
              Forgot password?
            </p>
            <div className="d-flex justify-content-evenly align-items-center w-100">
              <div className="left-line w-100 mx-4"></div>
            </div>
            <p className="mt-4 text-center">
              No worries, enter your email address and we'll send reset
              instructions
            </p>
            <div className="form-box w-100">
              <div className="mb-2">
                <label htmlFor="" className="label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-input"
                  placeholder="Enter Email"
                  name="email"
                  onChange={updateData}
                  value={data.email}
                  disabled={disable}
                />
                <small className="text-danger ml-2">
                  {messages.emailError}
                </small>
                <p className="text-success ml-2 text-center">
                  {messages.emailSuccess}
                </p>
                {messages.emailSuccess && (
                  <p className="text-primary ml-2 text-center">
                    {messages.emailCheck}
                  </p>
                )}
              </div>
            </div>
            {loading === true ? (
              <button className="login-btn" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className={
                  data.email === "" || disable
                    ? "login-btn-disable mt-2"
                    : "login-btn mt-2"
                }
                onClick={resetPassword}
              >
                Reset Password
              </button>
            )}

            <h5>
              Remember password?{" "}
              <Link to="/login">
                <span>Login</span>
              </Link>
            </h5>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="forgot-modal">
            <h2>Password Reset Success</h2>
            <p>Your Password has been successfully reset.</p>
            <div className="d-flex justify-content-center">
              <img src={forgotmodal} alt="" className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center">
              <Link className="login-btn my-2" to="/login">
                Login
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
