import React from "react";

export default function FAQ() {
  return (
    <>
      <div className="container ">
        <div className="row d-flex align-items-center justify-content-center m-2 p-4">
          <p>
            <strong>
              <span data-contrast="none">FAQ </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":240,"335559739":240,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ol>
            <li>
              <strong>
                <span data-contrast="none"> Buying Tickets</span>
              </strong>
            </li>
          </ol>
          <p>
            <strong>
              <span data-contrast="none">
                1.1 How do I find events near me?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Go to the Eventgem Homepage, go to the search bar, and search by
              artist, event, date, category, or venue. You can also just scroll
              down to pick a category from the list of categories. Keep
              scrolling further to discover events recommended for you, artists
              on tour, and any other upcoming events.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                1.2 What types of events are available?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Eventgem is your resource for discovering exciting events related
              to the Indian culture and community. Our comprehensive listings
              include cultural events, stand-up comedy shows, concerts, dance
              performances, and celebrations for all major Indian festivals.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                1.3 How do I sign up for Eventgem?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Signing up with Eventgem is quick.&nbsp;
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ul>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <span data-contrast="none">
                Press the Login button on the right corner of the Eventgem
                homepage.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <span data-contrast="none">
                You can sign up instantly with your Google, Facebook, or
                Instagram account,&nbsp;
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":60,"335559740":276}'>
                &nbsp;
              </span>
            </li>
          </ul>
          <p>
            <span data-contrast="none">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              OR
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ul>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <span data-contrast="none">
                Sign up with your email address and phone number and complete
                your registration.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559737":0,"335559738":60,"335559739":60,"335559740":276,"335559991":360}'>
                &nbsp;
              </span>
            </li>
          </ul>
          <p>
            <span data-contrast="none">
              Once you've registered and logged in, you'll unlock a world of
              amazing events right at your fingertips, and you can easily book
              your favorites.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                1.4 How can I purchase the tickets?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ul>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <span data-contrast="none">Log in to your Eventgem account.</span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":276}'>
                &nbsp;
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <span data-contrast="none">
                Choose the event you'd like to attend.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <span data-contrast="none">Add the tickets</span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":720,"335559737":0,"335559738":0,"335559739":0,"335559740":276,"335559991":360}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <span data-contrast="none">
                View the detailed pricing breakdown and available ticket tiers
                in the ticket summary placeholder.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <span data-contrast="none">
                Select the desired number of tickets and add any promotional
                codes if available.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="5"
              data-aria-level="1"
            >
              <span data-contrast="none">
                For events with a seat map, an interactive map will appear,
                allowing you to choose your seats.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="6"
              data-aria-level="1"
            >
              <span data-contrast="none">
                Once done, click on the Proceed button.
              </span>
              <span data-ccp-props='{"134245529":true,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="7"
              data-aria-level="1"
            >
              <span data-contrast="none">
                Now, enter your mode of payment and click confirm to complete
                the payment process.
              </span>
              <span data-ccp-props='{"134233118":false,"134245418":true,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559739":0,"335559740":240}'>
                &nbsp;
              </span>
            </li>
          </ul>
          <p>
            <strong>
              <span data-contrast="none">Note:</span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ol>
            <li>
              <span data-contrast="none">
                {" "}
                If you are paying through your debit or credit card, please save
                your card preferences for easier transactions in the future.
              </span>
            </li>
            <li>
              <span data-contrast="none">
                {" "}
                If you haven't registered yet, click 'Sign Up' to create your
                account and complete your payment.
              </span>
            </li>
          </ol>
          <p>
            <strong>
              <span data-contrast="none">
                1.5 What payment methods are accepted?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Eventgem offers flexible modes of payment and uses Stripe as our
              payment gateway.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ul>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="13"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="8"
              data-aria-level="1"
            >
              <span data-contrast="none">Credit Card</span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="13"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="9"
              data-aria-level="1"
            >
              <span data-contrast="none">Debit Card</span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="13"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="10"
              data-aria-level="1"
            >
              <span data-contrast="none">Google Pay</span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="13"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="11"
              data-aria-level="1"
            >
              <span data-contrast="none">PayPal</span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":276}'>
                &nbsp;
              </span>
            </li>
            <li
              data-leveltext="●"
              data-font="Georgia"
              data-listid="13"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
              data-aria-posinset="12"
              data-aria-level="1"
            >
              <span data-contrast="none">Apple Pay</span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":60,"335559740":276}'>
                &nbsp;
              </span>
            </li>
          </ul>
          <p>
            <span data-contrast="none">
              With our reliable payment gateway solution, you can rest assured
              that all your payments are encrypted, ensuring the utmost
              security. Your transaction information remains confidential and
              always protected, giving you peace of mind throughout your payment
              process.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                1.5.1 How do I know if my payment was successful, and the
                purchase is complete?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If the payment is confirmed, you'll receive a confirmation email
              to your registered email address
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                1.5.2 How do I receive my tickets?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              After your payment is confirmed, you'll receive a confirmation
              email to your registered email address with your purchase details
              and a PDF e-ticket attached for easy access.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              We will also be sending an e-ticket to your registered mobile
              number.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ol start="2">
            <li>
              <strong>
                <span data-contrast="none"> After the Purchase</span>
              </strong>
            </li>
          </ol>
          <p>
            <strong>
              <span data-contrast="none">
                2.1 Can I get a refund if I can't attend?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Our standard policy is that tickets are non-refundable.{" "}
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                2.2 What is your cancellation policy?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                2.2.1 On the occasion of an event being cancelled by the
                organizer
              </span>
            </strong>
            <span data-ccp-props='{"134233118":false,"134245529":true,"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559739":0,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Events may be canceled due to circumstances beyond our control
              (e.g., weather, artist illness). We'll email you as soon as
              possible to notify you of the cancellation. You'll generally
              receive a full refund to your original payment method but after
              deducting the platform and processing fee.
            </span>
            <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559737":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-ccp-props='{"134233117":false,"134233118":false,"134245529":true,"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559737":0,"335559738":0,"335559739":0,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                2.2.2 On the occasion of an event being cancelled by the
                customer
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              When booking an event, if you see &ldquo;Cancellation
              Available&rdquo; under their available event facilities, you can
              cancel your booking and receive a refund according to the event's
              specific cancellation policy.
            </span>
            <span data-ccp-props='{"134245418":true,"134245529":true,"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                2.3 What if an event is rescheduled?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If an event is rescheduled, your original tickets will usually be
              valid for the new date. If you can't attend the rescheduled date,
              please contact us, and we'll explore options based on the
              individual organizer&rsquo;s event policy.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                2.4 What happens if an event is canceled?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              You'll receive an email notification with instructions if an event
              is canceled. The event organizer determines refund policies, and
              you'll typically receive a full refund to your original payment
              method.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <ol start="3">
            <li>
              <strong>
                <span data-contrast="none">3. Troubleshooting</span>
              </strong>
            </li>
          </ol>
          <p>
            <strong>
              <span data-contrast="none">
                3.1 My payment didn't go through. What should I do?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              When the transaction fails, the primary reason could be incorrect
              payment details. If the payment doesn&rsquo;t go through even
              after entering the correct credentials, we suggest you try
              alternate modes of payment.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">3.1.1 Possible Technical Delay</span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If you received a payment deduction notification from your bank
              but no ticket confirmation email or SMS, it could be a temporary
              delay due to technical issues with your bank or our systems. Also,
              check your email's spam folder.{" "}
            </span>
            <span data-contrast="none">
              If you still haven't received your tickets
            </span>
            <strong>
              <span data-contrast="none">, </span>
            </strong>
            <span data-contrast="none">
              contact us at Eventgem Support with your registered email address
              and mobile number.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":240,"335559739":240,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">3.1.2 Booking Confirmation Issue</span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":240,"335559739":240,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If your payment was successful but can't find your booking details
              in your Eventgem account, double-check that you're using the
              correct login information. Otherwise, check your order history and
              request to send e-tickets again.
            </span>
            <span data-ccp-props='{"134233117":false,"134233118":false,"134245529":true,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":0,"335559739":0,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If still unresolved, search your email (including the spam folder)
              for any confirmation messages.&nbsp;
            </span>
            <span data-ccp-props='{"134233117":true,"134233118":true,"134245418":true,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":240,"335559739":240,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              If the aforementioned solutions fail to resolve your issue, please
              contact Eventgem Support. Provide them with your registered email
              address, mobile number, event name, and an estimate of when you
              made the booking.
            </span>
            <span data-ccp-props='{"134233117":false,"134233118":false,"134245529":true,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":0,"335559739":0,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-ccp-props='{"134233117":false,"134233118":false,"134245529":true,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":0,"335559739":0,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="none">
                3.2 The website is not loading correctly. How can I fix it?
              </span>
            </strong>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":0,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-contrast="none">
              Check your internet connection and try loading a different
              website. If the issue persists, reload the page, clear your
              browser's cache and cookies, or try a different browser. If the
              website still does not load, restart your device, disable browser
              extensions, or update your browser.
            </span>
            <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559738":60,"335559739":60,"335559740":276}'>
              &nbsp;
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
