import React, { useState, useEffect, useRef } from "react";
import "./css/Login.css";
import img from "../../Assets/login.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { TbBrandGoogle } from "../../Assets/google_color.js";
import us from "../../Assets/Us.svg";
import { FaInstagram } from "react-icons/fa6";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import validator from "validator";
import { signupVerify } from "../../redux/user";
import { useDispatch, useSelector } from "react-redux";
import store from "../../redux/store";
import { devApi } from "../../utils/constants";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Ref = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [typing, setTyping] = useState(false);
  const [strength, setStrength] = useState();
  const [passScore, setPassScore] = useState(0);
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const [signupdata, setSignupdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${devApi}/api/googleAuthLogin`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrlGoogle(data.url))
      .catch((error) => console.error(error));
  }, []);
  const updateData = (e) => {
    let { name, value } = e.target;
    setSignupdata({
      ...signupdata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
    // const isMatch = name === 'confirm_password' && signupdata.password === value;
    switch (name) {
      case "first_name":
        setErrors({
          ...errors,
          first_name: value.length === 0 ? "Name is required" : "",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      // case "confirm_password":setErrors({...errors,confirm_password: !isMatch ? "Password must not match the original password." : ""})
      // break;
      default:
        break;
    }
  };
  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;
    if (!signupdata.first_name) {
      dataIsValid = false;
      newErrors.first_name = "First Name is required";
    } else if (validator.isAlpha(signupdata.first_name)) {
      newErrors.first_name = "";
    } else {
      newErrors.first_name = "Please enter a valid first name";
    }
    if (!signupdata.last_name) {
      dataIsValid = false;
      newErrors.last_name = "Last Name is required";
    } else if (validator.isAlpha(signupdata.last_name)) {
      newErrors.last_name = "";
    } else {
      newErrors.last_name = "Please enter a valid last name";
    }
    if (!signupdata.email) {
      dataIsValid = false;
      newErrors.email = "Email is required";
    } else if (!regForEmail.test(signupdata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }
    if (signupdata.phone) {
      if (validator.isMobilePhone(signupdata.phone)) {
        newErrors.phone = "";
      } else {
        dataIsValid = false;
        newErrors.phone = "Please enter a valid mobile number";
      }
    }
    if (!signupdata.password) {
      dataIsValid = false;
      newErrors.password = "Password is required";
    } else {
      if (signupdata.password.length >= 8) {
        newErrors.password = "";
      } else {
        dataIsValid = false;
        newErrors.password = "Password should contain minimun 8 characters";
      }
    }
    if (signupdata.password !== signupdata.confirm_password) {
      dataIsValid = false;
      newErrors.confirm_password = "Password must match the original password.";
    } else {
      newErrors.confirm_password = "";
    }
    if (!dataIsValid) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };
  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    // Check password length
    if (password.length > 8) score += 1;
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    setPassScore(score);
    switch (score) {
      case 0:
      case 1:
      case 2:
        return (
          <span ref={Ref} className="weak">
            Weak
          </span>
        );
      case 3:
        return (
          <span ref={Ref} className="medium">
            Medium
          </span>
        );
      case 4:
        return (
          <span ref={Ref} className="strong">
            Strong
          </span>
        );
      case 5:
        return (
          <span ref={Ref} className="very-strong">
            Very Strong
          </span>
        );
      default:
        return null;
    }
  };
  const signup = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          return;
        }

        const body = {
          ...signupdata,
        };
        const headers = {
          "Content-Type": "application/json",
        };
        await axios
          .post(`${devApi}/api/registerClient`, body, headers)
          .then((res) => {
            if (res.data.success) {
              store.dispatch(signupVerify(res.data));
              navigate("/verifyemail");
            } else {
              if (res.data.error.email[0]) {
                setErrors({ ...errors, email: res.data.error.email[0] });
              }
            }
          })
          .catch((error) => {
            if (error?.response?.status === 450) {
              toast.error("Session Expired, Please log-in again");
              navigate("/login");
            }
          });
      } catch (error) {
        console.error("Signup error:", error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };
  return (
    <div className="login-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 head-box">
            <h3>
              Catch the coolest events in your town right here, right now!
            </h3>
            <img src={img} alt="" className="img-fluid " />
          </div>
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 signup-page">
            <Link to="/">
              <img src={eventgemLogo} alt="" />
            </Link>
            <h6>Welcome to hassle-free event bookings!</h6>
            <p>Create new account</p>
            <div className="d-flex justify-content-center align-items-center mb-2 w-25">
              {loginUrlGoogle ? (
                <a href={loginUrlGoogle}>
                  <TbBrandGoogle style={{ width: "32px", height: "30px" }} />
                </a>
              ) : (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </div>
            <div className="d-flex justify-content-evenly align-items-center w-100">
              <div className="left-line"></div>
              <h6 className="or">Or</h6>
              <div className="right-line"></div>
            </div>
            <div className="form-box w-100">
              {/*First Name Last Name*/}
              <div className="d-flex  mb-2 mt-2">
                <div
                  className="d-inline flex-column mb-2 "
                  style={{ width: "50%" }}
                >
                  <label htmlFor="" className="signup-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="signup-input-name"
                    placeholder="Enter First Name"
                    name="first_name"
                    onChange={updateData}
                    value={signupdata.first_name}
                  />
                  <small className="text-danger ml-2">
                    {errors.first_name}
                  </small>
                </div>
                <div
                  className="d-inline flex-column mb-2 ms-2"
                  style={{ width: "50%" }}
                >
                  <label for="formLastName" className="signup-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="formLastName"
                    className="signup-input-name"
                    placeholder="Enter Last Name"
                    name="last_name"
                    onChange={updateData}
                    value={signupdata.last_name}
                  />
                  <small className="text-danger ml-2">{errors.last_name}</small>
                </div>
              </div>

              {/*Mobile Number*/}
              <div className="mb-2">
                <label htmlFor="" className="signup-label">
                  Mobile Number
                </label>
                <div className="d-flex">
                  {" "}
                  <div
                    className="form-input4 text-center align-items-center"
                    style={{ padding: "12px 12px" }}
                  >
                    <img
                      src={us}
                      alt=""
                      className="img-fluid flag-img me-1"
                      style={{
                        width: "24px",
                        height: "23px",
                        marginBottom: 0,
                      }}
                    />{" "}
                    <small className="me-1 align-items-center">+1</small>
                  </div>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    className="signup-input ms-1"
                    onChange={updateData}
                    value={signupdata.phone}
                    placeholder="Enter your mobile number"
                  />
                </div>
                <small className="text-danger ml-2">{errors.phone}</small>
              </div>

              {/*Email */}
              <div className="mb-2">
                <label htmlFor="" className="signup-label">
                  Email
                </label>
                <input
                  type="text"
                  className="signup-input"
                  placeholder="Enter Email"
                  name="email"
                  onChange={updateData}
                  value={signupdata.email}
                />
                <small className="text-danger ml-2">{errors.email}</small>
              </div>

              {/*Password*/}
              <div className="mb-2">
                <div className="d-flex align-items-center justify-content-between">
                  <label for="password" className="signup-label">
                    Password
                  </label>
                  <div className="ms-2">{strength}</div>
                </div>
                <div className="d-flex align-items-center signup-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="signup-input-password"
                    placeholder="Enter Password"
                    name="password"
                    onChange={(event) => {
                      updateData(event);
                      setStrength(evaluatePasswordStrength(event.target.value));
                    }}
                    onFocus={() => setTyping(true)}
                    onBlur={() => setTyping(false)}
                    value={signupdata.password}
                    id="password"
                    aria-describedby="showHide"
                  />
                  <span
                    className="show-hide"
                    id="showHide"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye show-hide"></i>
                    ) : (
                      <i
                        className="bi bi-eye-slash show-hide"
                        id="togglePassword"
                      ></i>
                    )}
                  </span>
                </div>
                {typing && passScore !== 5 && (
                  <small className="text-primary ml-2 me-2">
                    Use 8 or more characters with a mix of letters, numbers &
                    symbols
                  </small>
                )}
                <small className="text-danger ml-2">{errors.password}</small>
              </div>

              {/*Confirm Password*/}
              <div className="mb-2">
                <label for="confirm_password" className="signup-label">
                  Confirm Password
                </label>
                <div className="d-flex align-items-center signup-input">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="signup-input-password"
                    placeholder="Re-Enter Password"
                    name="confirm_password"
                    onChange={updateData}
                    value={signupdata.confirm_password}
                  />
                  <span
                    className="show-hide"
                    id="showHide"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <i className="bi bi-eye show-hide"></i>
                    ) : (
                      <i className="bi bi-eye-slash show-hide"></i>
                    )}
                  </span>
                </div>
                <small className="text-danger ml-2">
                  {errors.confirm_password}
                </small>
              </div>
            </div>
            {loading === true ? (
              <div className="d-flex justify-content-center align-items-center">
                <button className="login-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            ) : (
              <button
                className={
                  signupdata.name === "" ||
                  signupdata.email === "" ||
                  signupdata.password === "" ||
                  signupdata.confirm_password === ""
                    ? "login-btn-disable"
                    : "login-btn"
                }
                onClick={signup}
              >
                Sign Up
              </button>
            )}
            <h5>
              Already have an Account?{" "}
              <Link to="/login">
                <span>Login</span>
              </Link>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
