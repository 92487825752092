import React from "react";
import { Link } from "react-router-dom";
import paymentFailed from "../../Assets/PaymentFailed.svg";
import "./Payments.css";
export default function PaymentFailed() {
  return (
    <>
      <div className="d-flex justify-content-center container ">
        <div className="row">
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <div className="title-failed"> Payment Failed</div>

            <img
              alt=".."
              className="img-fluid"
              style={{ width: "550px", height: "300px" }}
              src={paymentFailed}
            />
            <div className="d-flex justify-content-center align-items-center mb-5">
              <button className="proceedbtn">
                {" "}
                <Link to={`/checkout`}>Try Again</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
