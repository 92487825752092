import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import img from "../../Assets/login.png";
import logo from "../../Assets/logo.png";
import OtpInput from "react-otp-input";
import "./css/VerifyEmail.css";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import forgotmodal from "../../Assets/forgotmodal.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { signupVerify } from "../../redux/user";
import { devApi } from "../../utils/constants";

export default function VerifyEmail() {
  const signupDetails = useSelector((state) => state.user.signupDetails);
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [emailField, setEmailField] = useState(true);
  const [otpsent, setOtpsent] = useState(true);
  const [newPasswordField, setNewPasswordField] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const { state } = useLocation();
  const [errors, setErrors] = useState({
    email: "",
    otpError: "",
  });

  useEffect(() => {
    if (state) {
      handleResendEmailOtp();
      dispatch(signupVerify({}));
    }
  }, []);
  useEffect(() => {
    if (otpsent === true && seconds > 0) {
      const timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval);
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [otpsent, seconds]);
  const remainingSeconds = seconds % 60;
  const verifyOtp = async () => {
    setNewPasswordField(true);
    setOtpsent(false);

    // setLoadModal(true);
    setOtpsent(false);

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const body = {
      email: state ? state.email : signupDetails.data.email,
      password: state ? "" : signupDetails.raw_pass,
      client_id: state
        ? state.cust_account_id
        : signupDetails.data.cust_account_id,
      confirmation_code: otp,
    };

    await axios
      .post(`${devApi}/api/validateOTP`, body, config)
      .then((response) => {
        if (response.data.success) {
          setShowModal(true);
        } else {
          setErrors({ ...errors, otpError: response.data.message });
        }
      })
      .catch((error) => {
        setErrors({ ...errors, otpError: error.response.data.message });
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };
  const handleResendEmailOtp = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    };
    const body = {
      client_id: state
        ? state.cust_account_id
        : signupDetails.data.cust_account_id,
    };

    await axios
      .post(`${devApi}/api/resentOTP`, body, config)
      .then((response) => {
        if (response.data.success) {
          toast.success(
            `OTP sent to ${state ? state.email : signupDetails.data.email} `
          );
          setOtpsent(true);
          setSeconds(30);
        } else {
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };
  const location = useLocation();
  return (
    <div className="login-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 head-box">
            <h3>
              Catch the coolest events in your town right here, right now!
            </h3>
            <img src={img} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page">
            <Link to="/">
              <img src={eventgemLogo} alt="" />
            </Link>
            <h6>Welcome to hassle-free event bookings!</h6>
            <p style={{ fontWeight: "600", marginTop: "65px" }}>
              Create new account
            </p>
            <div className="d-flex justify-content-evenly align-items-center w-100">
              <div className="left-line w-100 mx-4"></div>
            </div>
            {errors.otpError && (
              <small className={"text-danger ml-2"}>{errors.otpError}</small>
            )}
            {state ? (
              ""
            ) : (
              <p>
                <Link
                  to="/signup"
                  className="link-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
                >
                  Click here to change you email address
                </Link>
              </p>
            )}
            <p className="mt-4 text-center mb-2">
              Enter the 4-digit verification code sent to you at{" "}
              <strong>{state ? state.email : signupDetails.data.email}</strong>
            </p>
            <div className="otp-inputs">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              <h6 className="d-flex justify-content-between w-100 mt-2">
                <span
                  className={
                    remainingSeconds == 0
                      ? "ms-3 resend-link"
                      : "ms-3 resend-link-inactive"
                  }
                  onClick={handleResendEmailOtp}
                >
                  Resend OTP?
                </span>{" "}
                <span
                  className="me-3 resend-link-inactive"
                  style={{ color: "#121212" }}
                >
                  {remainingSeconds}
                </span>
              </h6>
            </div>
            {loading === true ? (
              <button className="loading-btn mt-3">
                <div className="containers">
                  <div className="bar"></div>
                </div>
                Verify
              </button>
            ) : (
              <button
                className={
                  otp === "" ? "login-btn-disable mt-3" : "login-btn mt-3"
                }
                onClick={verifyOtp}
              >
                Verify
              </button>
            )}

            <h5>
              Already have an account?{" "}
              <Link to="/login">
                <span>Login</span>
              </Link>
            </h5>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="forgot-modal">
            <h2>Welcome to</h2>
            <div className="d-flex justify-content-center">
              <img
                src={eventgemLogo}
                alt=""
                className=""
                style={{ width: 300, height: 150 }}
              />
            </div>
            <p>
              Congratulations! Start exploring events you'll love.  No more FOMO
              with Eventgem
            </p>
            <div className="d-flex justify-content-center">
              <img src={forgotmodal} alt="" className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center">
              <Link className="login-btn my-2" to="/login">
                Login
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
// {
//     "success": true,
//     "message": "User created successfully",
//     "raw_pass": "Sumant@123",
//     "data": {
//         "first_name": "Sumant",
//         "last_name": "Test",
//         "email": "thakre.sumant@gmail.com",
//         "phone": null,
//         "country_code": null,
//         "cust_account_id": 40,
//         "is_parent": 1,
//         "is_registered": 1,
//         "confirm_code_exp": "2024-05-17T11:11:55.604364Z",
//         "confirmation_code": 4901,
//         "api_token": "ti0w1YNH38zaL14QNjMC3e9wcMAuWB3ChDvzNsuJ3pWrxB2idBjnAsjtjR1g",
//         "updated_at": "2024-05-17 11:10:55",
//         "created_at": "2024-05-17 11:10:55",
//         "id": 40
//     }
// }
