import React, { useState, useEffect } from "react";
import "./css/Login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/user";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
function GoogleCallback() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lastPage = useSelector((state) => state.user.lastVisited);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inputValue = urlParams.get("inputValue") || 23;
  // On page load, we take "search" parameters
  // and proxy them to /api/auth/callback on our Laravel API
  useEffect(() => {
    setLoading(true);
    fetch(`${devApi}/api/google/callback${location.search}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          dispatch(
            login({
              token: data.token,
              user: data.user,
            })
          );

          if (lastPage.includes("/login") || lastPage.includes("/signup")) {
            navigate(`/`);
          } else {
            navigate(lastPage);
          }
          toast.success("Login Successful");
          setLoading(false);
          setData(data);
        } else {
          setLoading(false);
          navigate("/login");
          toast.error(data.message);
        }
      });
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  // Helper method to fetch User data for authenticated user
  // Watch out for "Authorization" header that is added to this call
}

export default GoogleCallback;
